import { useEffect } from "react";
import { Link } from "react-router-dom";

const About = () => {
    useEffect(() => {
        document.title = 'about'
    }, []);
    return (
        <div style={{ marginTop: 20, marginLeft: '5%', marginRight: '10%' }}>
            <div>               <span style={{ color: '#6e5773', fontWeight: 'bold', fontSize: 22 }}>트랩</span>
                <span style={{ color: '#24527a', fontWeight: 'bold', fontSize: 22 }}>위키</span>
            </div>
            <br />
            <span style={{ color: 'red', fontWeight: 'bold', fontSize: 22 }}>tr</span>
            <span style={{ color: 'black', fontWeight: 'bold', fontSize: 22 }}>A</span>
            <span style={{ color: 'blue', fontWeight: 'bold', fontSize: 22 }}>pp</span>
            <br />
            <br />
            <div>트랩위키는 우리가 점점 더 온라인에 오래 머무는 이유를 분석합니다. </div>
            <br />
            <div>어플리케이션에 심어진 덫(trap)을 분석하고 개인적 차원에서 어떻게 디지털 기기를 통제적으로 사용할지 해결책을 고민합니다.</div>
            <div>정책과 제도를 통해 어떻게 더 나은 온라인 환경을 만들지도 살펴봅니다.</div>
            <br />
            <div>우리가 많이 사용하는 대표적인 앱인,</div>
            <br />
            <div>
                <Link to='/apps/유튜브' style={{ color: "#7c73e6", textDecoration: "none" }}>유튜브(비디오)</Link>,
                <br />
                <Link to='/apps/인스타그램' style={{ color: "#7c73e6", textDecoration: "none" }}>인스타그램(소셜미디어)</Link>,
                <br />
                <Link to='/apps/넷플릭스' style={{ color: "#7c73e6", textDecoration: "none" }}>넷플릭스(OTT)</Link>,
                <br />
                <Link to='/apps/네이버%20뉴스' style={{ color: "#7c73e6", textDecoration: "none" }}>네이버 뉴스</Link>,
                <br />
                <Link to='/apps/메이플스토리' style={{ color: "#7c73e6", textDecoration: "none" }}>메이플스토리(확률형 아이템 게임)</Link>,
                <br />
                <Link to='/apps/온라인%20커뮤니티' style={{ color: "#7c73e6", textDecoration: "none" }}>온라인 커뮤니티</Link>,
            </div>
            <br />
            <div>의
                디자인 원리를 분석합니다.</div>
            <br />
            다양한 어플리케이션의 디자인 원리를 역공학(逆工學, Reverse Engineering)하며 분석하는 태도를 연습합니다.
            <br />
            어플리케이션, 디자인 원리를 분석한 페이지마다 다양한 참고자료를 제시해 심리적, 사회적 영향에 대해 생각할 부분을 모았습니다.
            <br />
            <br />
            <Link to='/digitaldetox' style={{ color: "#7c73e6", textDecoration: "none" }}>'디지털 디톡스' 페이지</Link>에서는 가디언의 '두뇌 되찾기(Reclaim your brain)' 시리즈 등 여러가지 출처에서 소개된 디지털 기기를 통제적으로 사용하는 법을 모아두었어요.
            <br />
            <br />
            <Link to='/os' style={{ color: "#7c73e6", textDecoration: "none" }}>'OS' 페이지</Link>에서는 운영체제별로 해당 기기를 통제적으로 사용하는 기능들을 소개합니다.
            <br />

            <br />
            <div>
                트랩위키의 사이트 이름은 윤재영의 책{" "}
                <a href="https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=297851242" style={{ color: "#7c73e6", textDecoration: "none" }}>
                    『디자인 트랩』
                </a> {" "}(인터넷 서점 알라딘 링크)에 영감을 받아 지어졌습니다.
                <br />
                <br />
                비슷한 영어권 사이트로  {" "}
                <br />
                <br />
                <a href="https://www.deceptive.design/" style={{ color: "#7c73e6", textDecoration: "none" }}>
                    - https://www.deceptive.design/
                </a>
                <br />
                <a href="https://darkpatternstipline.org/" style={{ color: "#7c73e6", textDecoration: "none" }}>
                    - https://darkpatternstipline.org/
                </a>
                <br />
                <br />
                이 있습니다.
            </div>
        </div>
    )
}

export default About;
import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { LOGIN } from '../queries'
import { useNavigate } from 'react-router-dom'

const LoginForm = ({ setNotification, setToken }:  
    { setNotification: (message: string, type: 'success' | 'error') => void, setToken: React.Dispatch<React.SetStateAction<string | null>>}) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')


  const [ login, result ] = useMutation(LOGIN, {
    onError: (error) => {
      setNotification(error.message, 'error')
    }
  })
  const navigate = useNavigate();
  useEffect(() => {
    if ( result.data ) {
      const token = result.data.login.value
      setToken(token)
      localStorage.setItem('tw-user-token', token)
      navigate('/home')
    }
  }, [result.data]) // eslint-disable-line

  const submit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const result = await login({ variables: { username, password } })
    console.log(result)
    if(result.data) { 
      setNotification('login', 'success')
    }
  }

  return (
    <div>
      <form onSubmit={submit}>
        <div>
          username <input
            type='password'
            value={username}
            onChange={({ target }) => setUsername(target.value)}
          />
        </div>
        <div>
          password <input
            type='password'
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>
        <button type='submit'>login</button>
      </form>
    </div>
  )
}

export default LoginForm
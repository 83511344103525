import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_USER } from '../queries'
import { useNavigate } from 'react-router-dom'

const SignUp = ({ setNotification }:
  { setNotification: (message: string, type: 'success' | 'error') => void }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')


  const [signup] = useMutation(CREATE_USER, {
    onError: (error) => {
      setNotification(error.message, 'error')
    }
  })


  const navigate = useNavigate()

  const submit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const result = await signup({ variables: { username, password, name } })
    console.log(result);
    if (result.data) {
      setNotification('회원가입되었습니다.', 'success')
      navigate('/home')
    }
  }

  return (
    <div>
      <form onSubmit={submit}>
        <div>
          username <input
            type='password'
            value={username}
            onChange={({ target }) => setUsername(target.value)}
          />
        </div>
        <div>
          name<input
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
        </div>
        <div>
          password <input
            type='password'
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>
        <button type='submit'>회원가입</button>
      </form>
    </div>
  )
}

export default SignUp
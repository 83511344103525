import * as React from 'react';
import Box from '@mui/material/Box';

export function StyledBox({ children }: { children: any }) {
  return (
    <Box
      sx={{
        display: 'flex',           // Use flexbox
        alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: 'auto',
        height: 'auto',
        border: '2px solid white',
        backgroundColor: '#4586ff',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}>
      {children}
    </Box>
  );
}

export function AppStyledBox({ children, appType }: { children: any, appType: string }) {
  let pickedColor
  switch (appType) {
    case 'SOCIALMEDIA': pickedColor = '#8ef6e4'
      break;
    case 'COMMUNITY': pickedColor = '#5e63b6'
      break;
    case 'NEWS': pickedColor = '#118a7e'
      break;
    case 'OTT': pickedColor = '#ff847c'
      break;
    case 'VIDEO': pickedColor = '#dc2f2f'
      break;
    case 'GAME': pickedColor = '#fac70b'
      break;
    default: pickedColor = '#d55b3e'
      break;
  }
  return (
    <Box
      sx={{
        display: 'flex',           // Use flexbox
        alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: 'auto',
        height: 'auto',
        border: '2px solid white',
        backgroundColor: pickedColor,
        '&:hover': {
          backgroundColor: 'text.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}>
      {children}
    </Box>
  );
}

export function FixedStyledBox({ children }: { children: any }) {
  return (
    <Box
      sx={{
        display: 'flex',           // Use flexbox
        alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: '30',
        height: 50,
        border: '2px solid white',
        backgroundColor: '#4586ff',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
        borderRadius: '30px'
      }}>
      {children}
    </Box>
  );
}

export function FixedAppStyledBox({ children, appType }: { children: any, appType: string }) {
  let pickedColor
  switch (appType) {
    case 'SOCIALMEDIA': pickedColor = '#8ef6e4'
      break;
    case 'COMMUNITY': pickedColor = '#5e63b6'
      break;
    case 'NEWS': pickedColor = '#118a7e'
      break;
    case 'OTT': pickedColor = '#ff847c'
      break;
    case 'VIDEO': pickedColor = '#dc2f2f'
      break;
    case 'GAME': pickedColor = '#fac70b'
      break;
    default: pickedColor = '#d55b3e'
      break;
  }
  return (
    <Box
      sx={{
        display: 'flex',           // Use flexbox
        alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: '30',
        height: 50,
        border: '2px solid white',
        backgroundColor: pickedColor,
        '&:hover': {
          backgroundColor: `text.main`,
          opacity: [0.9, 0.8, 0.7],
        },
        borderRadius: '30px'
      }}>
      {children}
    </Box>
  );
}

export function HomeStyleddBox({ children, appType }: { children: any, appType: string }) {
  let pickedColor
  switch (appType) {
    case 'digitaldetox': pickedColor = '#3b4a6b'
      break;
    case 'os': pickedColor = '#41d8bf'
      break;
  }
  return (
    <Box
      sx={{
        display: 'flex',           // Use flexbox
        alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: '30',
        height: 50,
        border: '2px solid white',
        backgroundColor: pickedColor,
        '&:hover': {
          backgroundColor: `text.main`,
          opacity: [0.9, 0.8, 0.7],
        },
        borderRadius: '30px'
      }}>
      {children}
    </Box>
  );
}


export function AppBoxInPrinciple({ children, appType }: { children: any, appType: string }) {
  let pickedColor
  switch (appType) {
    case 'SOCIALMEDIA': pickedColor = '#8ef6e4'
      break;
    case 'COMMUNITY': pickedColor = '#5e63b6'
      break;
    case 'NEWS': pickedColor = '#118a7e'
      break;
    case 'OTT': pickedColor = '#ff847c'
      break;
    case 'VIDEO': pickedColor = '#dc2f2f'
      break;
    case 'GAME': pickedColor = '#fac70b'
      break;
    default: pickedColor = '#d55b3e'
      break;
  }
  return (
    <Box
      sx={{
        display: 'flex',           // Use flexbox
        alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: 150,
        height: 40,
        border: '2px solid white',
        backgroundColor: pickedColor,
        '&:hover': {
          backgroundColor: `text.main`,
          opacity: [0.9, 0.8, 0.7],
        },
      }}>
      {children}
    </Box>
  );
}

export function TextBox({ children }: { children: any }) {
  return (
    <Box
      sx={{
        width: 'auto',
        height: 'auto',
        border: '2px solid white',
        //backgroundColor: 'primary.dark',

      }}>
      {children}
    </Box>
  );
}

export function TextContainer({ children }: { children: any }) {
  return (
    <Box
      sx={{
        width: 'auto',
        height: 'auto',
        border: '1px dashed black',
      }}>
      {children}
    </Box>
  );
}

export function LinkBox({ children }: { children: any }) {
  return (
    <Box
      sx={{
        display: 'flex',           // Use flexbox
        alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: 150,
        height: 40,
        border: '2px solid white',
        backgroundColor: '#4586ff',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        }
        //borderRadius: '20%'
      }}>
      {children}
    </Box>
  );
}


export function SuggestionBox({ children }: { children: any }) {
  return (
    <Box
      sx={{
        //display: 'flex',           // Use flexbox
        //alignItems: 'center',      // Vertically center items
        justifyContent: 'center',  // Horizontally center items
        width: '30',
        height: 50,
        //border: '2px solid white',
        //backgroundColor: '#4586ff',
        '&:hover': {
          color: "#7c73e6",
          opacity: [0.9, 0.8, 0.7],
        },
      }}>
      {children}
    </Box>
  );
}

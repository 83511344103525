import { useEffect, useState } from 'react'
import { AppTypeWithComment } from '../types'
import Button from '@mui/material/Button';
import EditPrinciple from './EditPrinciple'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { FIND_PRINCIPLE } from '../queries'
import { Link } from 'react-router-dom'
import { AppBoxInPrinciple, TextBox, TextContainer } from './styled_components/Boxs'
import Loading from './Loading';

const PrincipleInfo = ({ setNotification, token }: {
    setNotification: (message: string, type: 'success' | 'error') => void, token: string | null
}) => {
    const navigate = useNavigate()
    const [openEditor, setOpenEditor] = useState(false);

    let { koreanName } = useParams()
    //let idToSearch = parseInt(id as string)
    koreanName ??= ""
    const { data, loading, error } = useQuery(FIND_PRINCIPLE, {
        variables: { koreanName },
        skip: !koreanName
    })

    useEffect(() => {
        if (data?.findPrinciple.names.koreanName) {
            document.title = data.findPrinciple.names.koreanName;
        }
    }, [data?.findPrinciple.names.koreanName]);

    if (loading) return <Loading />
    if (error) return <p>ERROR: {error.message}</p>;
    if (!data) return <p>Not found</p>;
    const padding = {
        textDecoration: "none",
        color: "white"
    }
    let i = 1;

    return (
        data.findPrinciple &&
        <div style={{ marginLeft: '1.5%', marginRight: '1.5%', marginBottom: "2%" }}>
            {openEditor ? null :
                <div>
                    <h2>{`${data.findPrinciple.names.koreanName} ${data.findPrinciple.names.englishName}`}</h2>
                    <hr />
                    <div style={{ whiteSpace: 'break-spaces' }}>
                        {data.findPrinciple.description}
                    </div>
                    <hr />
                    <div>
                        {data.findPrinciple.using_apps.map((a: AppTypeWithComment) =>
                            <div key={a.id}>
                                <div style={{ display: 'flex'}}>
                                <Link to={`/apps/${a.names.koreanName}`} style={padding}>
                                    <AppBoxInPrinciple appType={a.appType}>
                                        {a.names.koreanName}
                                    </AppBoxInPrinciple>
                                </Link>
                                </div>
                                <TextContainer>
                                    {/* <TextBox>
                                                            {a.description}
                                                        </TextBox>
                                                        <hr style={{ border: '0.2px dashed #000' }} /> */}
                                    <TextBox>
                                        {a.app_principles.comment}
                                    </TextBox>
                                </TextContainer>
                                <br />
                            </div>)
                        }</div>
                    <hr />
                    <div><span>대처법  ➡</span> {data?.findPrinciple?.countermeasure}</div>
                    <hr />
                    <div>참고자료  ➡<div>{data?.findPrinciple?.prin_refs.map((ref: any) => {
                        return <div key={ref.id}>
                            {i++ + ". " + ref.title + " | " + ref.author + " | "}
                            <a href={ref.link} style={{ color: "#7c73e6", textDecoration: "none" }}>{ref.linkTitle}{`(${ref.link?.slice(0, 15)}···)`}</a>
                            {" | " + ref.description}
                        </div>
                    })}</div></div>
                    <div style={{ display: 'flex', justifyContent: document.documentElement.clientWidth <= 1300 ? 'flex-end' : 'flex-start' }}>
                        {token ? <Button variant="contained" onClick={() => setOpenEditor(!openEditor)}>수정</Button> : null}
                        <Button variant="contained" color="error" onClick={() => navigate('/principles')}>닫기</Button>
                    </div>
                </div>}
            {openEditor ? <EditPrinciple setNotification={setNotification} principle={data.findPrinciple} setOpenEditor={setOpenEditor} /> : null}
        </div>
    )
}

export default PrincipleInfo
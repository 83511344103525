import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { PrincipleTypeWithComment } from '../types'
import { FIND_APP } from '../queries'
import EditApp from './EditApp'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import { Box } from '@mui/material'
import { LinkBox, TextBox, TextContainer } from './styled_components/Boxs'
import Loading from './Loading'

const AppInfo = ({ setNotification, token }: {
    setNotification: (message: string, type: 'success' | 'error') => void, token: string | null
}) => {
    const navigate = useNavigate()
    const [openEditor, setOpenEditor] = useState(false);// onClose, , onClose: () => void

    let { koreanName } = useParams()
    //let idToSearch = parseInt(id as string)
    koreanName ??= ""
    const { data, loading, error } = useQuery(FIND_APP, {
        variables: { koreanName },
        skip: !koreanName
    })

    useEffect(() => {
        if (data?.findApp.names.koreanName) {
            document.title = data.findApp.names.koreanName;
        }
    }, [data?.findApp.names.koreanName]);
    //const titleElement = document.getElementById('dynamicTitle');

    // useEffect(() => {
    //     if (data?.findApp.names.koreanName) {
    //         document.title = data.findApp.names.koreanName;
    //     }
    // }, [data?.findApp.names.koreanName]);

    if (loading) return <Loading />
    if (error) return <p>ERROR: {error.message}</p>;
    if (!data) return <p>Not found</p>;
    
    // if (titleElement) {
    //     // titleElement is not null, so it's safe to access its properties.
    //     titleElement.innerHTML = data.findApp.names.koreanName;
    // } else {
    //     // Handle the case where titleElement is null.
    //     console.error("titleElement is null.");
    // }
    const padding = {
        textDecoration: "none",
        //color: "inherit"
        color: 'white'
    }
    let apptype;
    switch (data.findApp.appType) {
        case "SOCIALMEDIA": apptype = "소셜미디어";
            break;
        case "COMMUNITY": apptype = "온라인 커뮤니티";
            break;
        case "NEWS": apptype = "뉴스";
            break;
        case "OTT": apptype = "OTT";
            break;
        case "VIDEO": apptype = "동영상";
            break;
        case "GAME": apptype = "확률형 아이템 게임"
            break;
        default: apptype = "기타";
    }
    let i = 1;
    return (
        data.findApp &&
        <div style={{ marginLeft: '1.5%', marginRight: '1.5%', marginBottom: "2%" }}>
            {openEditor ? null :
                <div>
                    {/* <div style={{display: 'flex', justifyContent: 'space-between'}}> */}
                    <h2 style={{}}>{`${data.findApp.names.koreanName} ${data.findApp.names.englishName}`}
                    </h2>
                    <Box
                        sx={{ marginTop: -2, marginBottom: 3, fontSize: 15, color: 'violet' }}>
                        {apptype}</Box>
                    {/* </div> */}
                    <hr />
                    <div style={{ whiteSpace: 'break-spaces' }}>
                        {data.findApp.description}
                    </div>
                    <br />
                    <div>{data.findApp.used_principles.map((p: PrincipleTypeWithComment) =>
                        <div key={p.id}>
                            <div style={{ display: 'flex'}}>
                            <Link to={`/principles/${p.names.koreanName}`} style={padding}>
                                <LinkBox>
                                   {p.names.koreanName}
                                </LinkBox>
                            </Link>
                            </div>
                            <TextContainer>
                                <TextBox>
                                    {p.description}
                                </TextBox>
                                <hr style={{ border: '0.2px dashed #000' }} />
                                <TextBox>
                                    {p.app_principles.comment}
                                </TextBox>
                            </TextContainer>
                            <br />
                        </div>
                    )}
                    </div>
                    <hr />
                    <div><span>비슷한 앱  ➡</span> {data?.findApp?.similarApp}</div>
                    <hr />
                    <div><span>대처법  ➡</span> {data?.findApp?.countermeasure}</div>
                    <hr />
                    <div><span>대안  ➡</span> {data?.findApp?.alternative}</div>
                    <hr />
                    <div>참고자료  ➡<div>{data?.findApp?.app_refs.map((ref: any) => {
                     
                        return <div key={ref.id}>
                            {i++ + ". " + ref.title + " | " + ref.author + " | "}
                            <a href={ref.link} style={{ color: "#7c73e6", textDecoration: "none" }}>{ref.linkTitle}{`(${ref.link?.slice(0, 15)}···)`}</a>
                            {" | " + ref.description}
                        </div>
                    })}</div></div>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: document.documentElement.clientWidth <= 1300 ? 'flex-end' : 'flex-start' }}>
                        {token ? <Button variant="contained" onClick={() => setOpenEditor(!openEditor)}
                        >수정</Button> : null}
                        <Button variant="contained" color="error" onClick={() => navigate('/apps')}
                        >닫기</Button>
                    </div>
                </div>}
            {openEditor ? <EditApp setNotification={setNotification} app={data.findApp} setOpenEditor={setOpenEditor} /> : null}
        </div>
    )
}

export default AppInfo;
import { useQuery } from '@apollo/client'
import PrincipleForm from './components/PrincipleForm';
import Notify from './components/Notify';
import { ALL_APPS, ALL_PRINCIPLES } from './queries';
import { useState } from 'react';
import Navigation from './components/navigation/Navigation';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppForm from './components/AppForm';
import LoginForm from './components/Login';
import SignUp from './components/SignUp';
import AppList from './components/AppList';
import PrincipleInfo from './components/PrincipleInfo';
import PrincipleList from './components/PrincipleList';
import AppInfo from './components/AppInfo';
import Home from './components/Home';
import ForXml from './components/ForXml';
import About from './components/About';
import OS from './components/OS';
import Suggestion from './components/Suggestion';
import DigitalDetox from './components/DigitalDetox';
import { StyledDiv } from './style';
import Loading from './components/Loading';
import Donation from './components/Donation';




const App = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("tw-user-token") || null)
  const [message, setMessage] = useState<string | null>(null)
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
  const [notifyStyle, setNotifyStyle] = useState<boolean>(true);
  //export default 
  // const [isScrolled, setIsScrolled] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Check the scroll position, e.g., when it's greater than 100px from the top
  //     const scrollY = window.scrollY || window.pageYOffset;
  //     setIsScrolled(scrollY > 100); // Adjust the threshold as needed
  //   };

  //   // Attach the scroll event listener
  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);


  const principleResult = useQuery(ALL_PRINCIPLES)
  const appResult = useQuery(ALL_APPS)


  if (principleResult.loading) {
    return <Loading />
  }

  if (appResult.loading) {
    return <Loading />
  }


  const notify = (message: string, type: 'success' | 'error') => {
    setMessage(message)
    setMessageType(type);
    setNotifyStyle(notifyStyle);
    setTimeout(() => {
      setMessage(null)
      setMessageType(null)
    }, 5000)
  }


  // style={{ marginLeft: '2%', marginRight: '2%', marginTop: '2%'}}
  //margin: 0, padding: 0 
  //style={{ paddingTop: '55px' }}

  return (
    <StyledDiv>
      <Router>
        <Navigation setToken={setToken} token={token} setNotification={notify} />
        <Notify message={message} messageType={messageType} />
        <Routes>
          <Route path="/" element={<Home principles={principleResult.data.allPrinciples} apps={appResult.data.allApps} 
          setNotification={notify} />} />
          {/* <Route path="/principles"
            element={<PrincipleList principles={principleResult.data.allPrinciples} setNotification={notify} token={token} />}>
          </Route> */}
          <Route path="/principles/:koreanName" element={<PrincipleInfo setNotification={notify} token={token} />}></Route>
          <Route path="/principles/:searchToId" element={<PrincipleInfo setNotification={notify} token={token} />}></Route>
          <Route path="/new_principle" element={<PrincipleForm setNotification={notify} />} />
          {/* <Route path="/apps" element={<AppList apps={appResult.data.allApps} />}></Route> */}
          <Route path="/apps/:koreanName" element={<AppInfo setNotification={notify} token={token} />}></Route>
          <Route path="/new_app" element={<AppForm setNotification={notify} />} />
          <Route path="/login" element={<LoginForm setNotification={notify} setToken={setToken} />} />
          <Route path="/signup" element={<SignUp setNotification={notify} />} />
          <Route path="/suggestion" element={<Suggestion />} />
          <Route path="/about" element={<About />} />
          <Route path="/os" element={<OS />} />
          <Route path="/digitaldetox" element={<DigitalDetox />} />
          <Route path="/donation" element={<Donation />} />
          
          {/* <Route path="/:id/change_principle" element={<DescriptionForm setError={notify} koreanName={}/>}/> */}
          {/* <Route
            path="/login"
            element={
              <LoginForm
                setToken={setToken}
                setError={notify}
              />
            }
          />
          <Route path="/newbook" element={<NewBook setError={notify} />} />
          
          <Route
            path="/recommend"
            element={<Recommend token={token} />} /> */}
          <Route path="/for_xml" element={<ForXml principles={principleResult.data.allPrinciples} apps={appResult.data.allApps} />} />
        </Routes>
      </Router>
    </StyledDiv>
  )
}

export default App
import * as React from 'react';
import '../styles.css';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { ToolbarPlugin } from './toolbar/Toolbar';
import { BannerNode } from './plugins/banner/BannerPlugin';
import { $createTextNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $getSelection } from 'lexical';
import { $createParagraphNode } from 'lexical';

const theme = {
    heading: {
        h1: 'trap-editor-h1',
        h2: 'trap-editor-h2',
        h3: 'trap-editor-h3'
    },
    text: {
        bold: 'trap-editor-bold',
        italic: 'trap-editor-italic'
    },
    banner: 'trap-editor-banner'
};


function onError(error: Error): void {
    console.error(error);
}

const EditorCapturePlugin = React.forwardRef((props: any, ref: any) => {
    const [editor] = useLexicalComposerContext();

    React.useEffect(() => {
        if (editor) {
        editor.update(() => {
            // Get the RootNode from the EditorState
            const root = $getRoot();
    
            // Get the selection from the EditorState
            const selection = $getSelection();
    
            // Create a new ParagraphNode
            const paragraphNode = $createParagraphNode();
    
            // Create a new TextNode
            const textNode = $createTextNode(props.description);
    
            // Append the text node to the paragraph
            paragraphNode.append(textNode);
    
            // Finally, append the paragraph to the root
            root.clear();
            root.append(paragraphNode); 
            console.log('1')

        });
    }
        ref.current = editor;
        //   return () => {
        //     ref.current = null;
        //   };
    }, [editor, ref, props.description]);

    return null;
})

// const loadContent = async () => {
//     // 'empty' editor
//     const value = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

//     return value;
// }

// const initialEditorState = await loadContent();

export const Editor = React.forwardRef((props: any, ref: any) => {
    const initialConfig = {
        namespace: 'MyEditor',
        theme,
        onError,
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            BannerNode
        ],
        //editorState: initialEditorState
    };

    // const onChange = (editorState: any) => {
    //     editorState.read(() => {
    //         const json = editorState.toJSON();
    //         console.log(JSON.stringify(json));
    //     })
    // }

    return (
        <LexicalComposer initialConfig={initialConfig} >
            <ToolbarPlugin />
            {/* <ListPlugin/>
                <BannerPlugin/> */}
            <RichTextPlugin
                contentEditable={<ContentEditable className="contentEditable" />}
                placeholder={<div className="placeholder">설명을 적어주세요...</div>}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <EditorCapturePlugin ref={ref} description={props.description} />
            {/* <OnChangePlugin onChange={onChange} /> */}
            <HistoryPlugin />
        </LexicalComposer>
    );
});

// export default function Editor({ setDescription, ref }: { setDescription: React.Dispatch<React.SetStateAction<string>>,
// ref: React.Ref<unknown> | undefined } ): JSX.Element {
//     const initialConfig = {
//         namespace: 'MyEditor',
//         theme,
//         onError,
//         nodes: [
//             HeadingNode,
//             ListNode,
//             ListItemNode,
//             BannerNode
//         ]
//     };
//     const editorStateRef = React.useRef();

//     return (
//         <LexicalComposer initialConfig={initialConfig}>
//             {/* <ToolbarPlugin/> */}
//             {/* <ListPlugin/>
//             <BannerPlugin/> */}
//             <RichTextPlugin
//                 contentEditable={<ContentEditable className="contentEditable" />}
//                 placeholder={<div className="placeholder">설명을 적어주세요...</div>}
//                 ErrorBoundary={LexicalErrorBoundary}
//             />
//             <EditorCapturePlugin ref={ref} />
//             <HistoryPlugin />
//             <MyOnChangePlugin onChange={(editorState) => { 
//               console.log(editorStateRef.current)
//               setDescription(JSON.stringify(editorStateRef.current))}}/>
//         </LexicalComposer>
//     );

// };


// function MyOnChangePlugin(props: { onChange: (editorState: EditorState) => void}): null {
//     const [editor] = useLexicalComposerContext();
//     const { onChange } = props;
//     React.useEffect(() => {
//         return editor.registerUpdateListener(({editorState}) => {
//             console.log(editorState);
//             onChange(editorState);
//         })
//     }, [onChange, editor])
//     return null;
// }
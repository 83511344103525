import { useState, SyntheticEvent, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_PRINCIPLE, ALL_PRINCIPLES } from '../queries'
import { Editor } from './Editor/Editor';
import { $getRoot, LexicalEditor } from 'lexical';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useNavigate } from 'react-router-dom';


const PrincipleForm = ({ setNotification }: { setNotification: (message: string, type: 'success' | 'error') => void }) => {
  const [koreanName, setKoreanName] = useState('')
  const [englishName, setEnglishName] = useState('')
  //const [description, setDescription] = useState<string|undefined>('')
  //const [editorState, setEditorState] = useState<string | undefined>();
  //const [apps, setApps] = useState<AppType[]>([])
  //const [app, setApp] = useState('');
  //let description: string;
  const editorRef = useRef<LexicalEditor | null>();
  // if (editorRef.current !== undefined) {
  //   if (editorRef.current !== null) {
  //     const latestEditorState = editorRef.current.getEditorState();
  //     const textContent = latestEditorState.read(() =>
  //       $getRoot().getTextContent()
  //     );
  //     console.log(textContent);
  //     description = textContent;
  //   }
  // }

  const [createPrinciple] = useMutation(CREATE_PRINCIPLE, {
    //refetchQueries: [ { query: ALL_PRINCIPLES } ],
    onError: (error) => {
      //const messages = error.graphQLErrors[0].message
      //const messages = error
      console.log(error.message)
      setNotification(error.message, 'error');
    },
    update: (cache, response) => {
      cache.updateQuery({ query: ALL_PRINCIPLES }, ({ allPrinciples }) => {
        return {
          allPrinciples: allPrinciples.concat(response.data.addPrinciple),
        }
      })
    },
  })
  // useEffect(() => {
  //   const latestEditorState = editorRef?.current?.getEditorState();
  //   const textContent = latestEditorState?.read(() =>
  //     $getRoot().getTextContent()
  //   );
  //   setDescription(textContent);
  //   console.log(textContent)
  // }, [editorRef])
  const navigate = useNavigate();
  const submit = async (event: SyntheticEvent) => {
    event.preventDefault()
    
    const latestEditorState = editorRef.current?.getEditorState();

    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    console.log("textCotent", textContent);
    const description = textContent;
    console.log("description", description);

    const result = await createPrinciple({ variables: { koreanName, englishName, description } })
    if(result.data) {
    setKoreanName('')
    setEnglishName('')
    navigate('/principles')
    setNotification('추가되었습니다', 'success')
    }

    //navigate('/principles')
    //setDescription('')
    //setApps([])

    if (editorRef.current !== null)
    editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }

  // const addApp = () => {
  //   setApps(apps.concat(app))
  //   setApp('');
  // }


  return (
    <div style={{marginLeft: '1.5%', marginRight: '1.5%'}}>
      <h2>추가하기</h2>
      <form onSubmit={submit}>
        <div>
          이름 <input value={koreanName}
            onChange={({ target }) => setKoreanName(target.value)}
          />
        </div>
        <div>
          영문 이름 <input value={englishName}
            onChange={({ target }) => setEnglishName(target.value)}
          />
        </div>
        <div  className="editorWrapper">
          {/* <input value={description}
            onChange={({ target }) => setDescription(target.value)}
          /> */}
          <Editor ref={editorRef}/>
        </div>
        {/* <div>어플리케이션: {apps.join(' ')}</div> */}
        {/* <div>
          앱 <input value={app}
            onChange={({ target }) => setApp(target.value)}
          />
          <button onClick={addApp} type="button">
            앱 추가
          </button>
        </div> */}
        <button type='submit' id='submit-button'>추가!</button>
      </form>
    </div>
  )
}

export default PrincipleForm;
import { useEffect } from "react";
import { Link } from "react-router-dom";

const DigitalDetox = () => {
    useEffect(() => {
        document.title = '디지털 디톡스'
    }, []);
    return (
        // marginLeft: '5%', marginRight: '10%'
        <div>
            <div style={{ marginLeft: 20 }}>
            <h2>디지털 디톡스를 위한 몇 가지 제안들</h2>
                <h3>1. 가디언 - 두뇌 되찾기(Reclaim your brain) 시리즈</h3>
                언론사 가디언의 2024년 새해 시리즈 <a href="https://www.theguardian.com/lifeandstyle/2023/dec/14/sign-up-to-reclaim-your-brain-our-free-email-to-help-you-scroll-less-and-live-more?utm_term=65a51f88489a6d98cda85b91548e9fa4&utm_campaign=ReclaimYourBrain&utm_source=esp&utm_medium=Email&CMP=reclaimyourbrain_email"
                    style={{ color: "#7c73e6", textDecoration: "none" }}>'두뇌 되찾기(Reclaim your brain)'</a>에는 몇 가지 조언이 소개된다.
                <br />
                이 시리즈에서 소개된 조언에 추가적인 아이디어(+표시)를 더해보았다.
                <br />
                <br />
                1. 휴대폰이 아닌 알람 시계 이용하기 / 휴대폰 침실에서 충전하지 않기
                <br />(원룸이라면 침대에서 떨어진 곳에서 휴대폰 충전하기)
                <br />
                <br />
                2. 휴대폰에 방지턱 만들기
                <br />(휴대폰에 고무줄이나 머리띠 채우기)
                <br />
                <br />
                + 복잡한 비밀번호로 방지턱 만들기(아이폰)
                <br />(아이폰의 경우, 스크린 타임 비밀번호를 기억하지 못하게 무작위로 설정해도 애플 계정 로그인을 통해 스크린 타임 비밀번호를 재설정할 수 있다.
                <br /> 애플 계정의 비밀번호를 아주 복잡하게 만들면 스크린 타임 해제를 방지할 수 있다.)
                <br />
                + 잠금 어플리케이션으로 방지턱 만들기(안드로이드)
                <br />(안드로이드의 경우, 화면 잠금 어플리케이션을 통해 사용할 어플리케이션을 정해서 이용할 수 있다.)
                <br />
                <Link to='/os' style={{ color: "#7c73e6", textDecoration: "none" }}>+ 트랩위키 '운영체제별 대처법' 페이지 참조</Link>
                <br />
                <br />
                3. 휴대폰 알림 끄기
                <br />
                <Link to='/principles/알림' style={{ color: "#7c73e6", textDecoration: "none" }}>+ 트랩위키 '알림'의 참고자료에 안드로이드/iOS 알림 관련 링크가 있다.</Link>
                <br />
                <br />
                4. 유혹 앱(Temptation App)은 지우고, 도구 앱(Tool App)만 남기기
                <br />
                휴대폰에{" "}
                <Link to='/apps/인스타그램' style={{ color: "#7c73e6", textDecoration: "none" }}>소셜미디어,{" "}</Link>
                <Link to='/apps/유튜브' style={{ color: "#7c73e6", textDecoration: "none" }}>유튜브{" "}</Link>
                등 유혹 앱은 지우고, 도구 앱(지도, 은행 앱 등)만 남긴다.
                <br />
                <br />
                5(+). 1번에서 알람 시계로 스마트폰을 대체했던 것처럼, 다른 영역에서도 스마트폰의 대체품을 찾을 수 있다.
                <br/>
                예를 들면, 닌텐도 등의 휴대용 게임기로 광고 및 <a href="/principles/확률형 아이템" style={{ color: "#7c73e6", textDecoration: "none" }}>
         확률형 아이템,
          </a>{" "}<a href="/principles/출석 보상 시스템" style={{ color: "#7c73e6", textDecoration: "none" }}>
         출석 보상 시스템
          </a>{" "} 없이 더 괜찮은 게임을 즐길 수 있다.
                <br/>
                전자사전으로 스마트폰 알림 등의 방해 없이 사전을 이용할 수 있다.
            </div>
            <div style={{ marginLeft: 20 }}>
            <h3>2. 집에서 스마트폰을 놓아둘 곳 마련하기</h3>
            <iframe width="60%" height="315" src="https://www.youtube.com/embed/aZcbJl1IOWM?si=cLKO6eNTYSdVEA--&amp;start=920" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            <br /><br />
            위 영상의 15분 20초의 예시처럼 집에서 활동하는 동선으로부터 떨어진 곳에 스마트폰을 놓아두면 사용 시간을 줄일 수 있다.
            <br />
            1-1에서 언급한 스마트폰을 침실 바깥에 두는 것과 연결되는 사항이다.
            </div>
            <div style={{ marginLeft: 20 }}>
            <h3>3. 나만의 유튜브, OTT 프로그램 편성표 만들어보기</h3>
            소셜미디어나 유튜브, OTT를 이용하다보면 계획 없이 무작위로 영상을 보게된다(둠 스크롤링).
            <br />
            무언가 중요한 영상을 놓칠지 모른다는 두려움(FOMO)으로 그날 하루하루 만나게 되는 영상을 계속 보게 된다.
            <br />
            유튜브의 '나중에 볼 동영상에서 동영상' 기능 등을 이용하거나 직접 메모해서 나중에 확인할 영상들을 기록해둔다.
            <br />
            달력(스캐줄러) 프로그램이나 다이어리의 달력 등을 이용해서 나만의 프로그램 편성표를 만들면 무작위 영상 시청을 예방할 수 있다.
            <br />
            <img src={require('../scheduler.jpg')} alt="" width={'60%'} height={250}/>
            </div>
            <h3 style={{ marginLeft: 20 }}>4. 영화관과 같은 대안적 장소들 찾아보기</h3>

            <div style={{
   marginLeft: 20 
            }}>
                            <div style={{
                    // position: 'absolute', top: document.documentElement.clientWidth <= 1300 ? '105%' : '55%',
                   color: '#7c73e6'
                }}><a href="http://www.cine21.com/news/view/?mag_id=104793" style={{ color: "#7c73e6" }}>
                    [인터뷰] 영화비평과 ‘필름적 현실’의 특권화, 하스미 시게히코 ②(링크)</a></div>
                <br/>  
                <div style={{
                  color: '#7c73e6'
                }}>
                    "다만 주변 세계에 어떤 시선을 보내야 하는지에 대해서는 각자 나름대로 민감해야 한다고 생각한다. 현실 사회라는 것의 실태와 주변에 유통되고 있는 그 전자적(電子的)인 표상작용과의 관계를 식별하는 것이 매우 곤란해지고 있다. 때문에 자신이 잘못된 판단을 내렸다고 자각했을 경우, 그것을 수정하는 능력만큼은 몸에 익혔으면 한다. 지금은 AI를 비롯한 주변의 환경으로 인해 올바름에 대한 판단이 어려운 시대다. 이럴 때 필요한 건 전자적인 환경 그 자체로부터 거리를 두는 것이다. 그보다는 극장에 가서 스크린에 투영되는 ‘영화의 필름적 현실’과 무심하게 마주하길 바란다."
                    <br/>  <br/>
                    "그러나 영화란 고독한 동시에 또 집단적인 체험이기도 하다. 때문에 PC나 휴대전화의 미소한 화면과는 다른, 영화관의 큰 스크린을 앞에 두고 많은 관객과 동시에 영화의 ‘필름적인 현실’의 추이를 공유해주었으면 했다. 그것은 휴대 가능한 전자적인 기기의 작은 화면으로 영화를 보는 것과는 전혀 이질적인 체험이다. 때로는 스마트폰으로부터 해방되어 영화관을 발견해주길 바란다."
                </div>
                <br/>
                <div>위의 말에서 어떻게 스마트폰에서 떨어져서 다른 현실을 경험할지 힌트를 얻을 수 있다. 영화관처럼 휴대폰을 끄는 장소에 방문한다면 휴대폰으로부터 잠시나마 멀어져서 다른 현실을 경험할 수 있다.</div>
            </div>
            <h3 style={{ marginLeft: 20 }}>5. 비극을 멈추는 동사들 - 휴대폰하다</h3>
            <div style={{
                marginTop: 20, position: 'relative',
                marginLeft: 20,
                paddingBottom: '56.25%',
                /* 16:9 */
                paddingTop: '25px',
                height: 0
            }}>
                <iframe style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: document.documentElement.clientWidth <= 1300 ? '95%' : '60%',
                    height: document.documentElement.clientWidth <= 1300 ? '100%' : '50%',
                }}
                    width="60%" height="349" src="https://www.youtube-nocookie.com/embed/VRmCY0PaWNU?si=C_jMgX7usYg2r9U5" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                <div style={{
                    position: 'absolute', top: document.documentElement.clientWidth <= 1300 ? '105%' : '55%',
                    width: document.documentElement.clientWidth <= 1300 ? '95%' : '60%',
                    marginLeft: 5, marginRight: 20, color: '#7c73e6'
                }}>
                    "남편은 계속해서 모든 일상생활에서 스마트폰 앱을 사용했어요. 하나씩 자신의 단점을 보완해 가며 완벽한 남편이 되어 가고 있었지요. 문제는 그다음이었습니다. 남편은 점점 더 완벽해져 갔지만, 남편을 완벽하게 만들어 준 이 앱은 뜻밖에 완벽하지 못했습니다. 전 세계의 정보를 수집해 전 인류와 공유하는 이 시스템은 지구 밖에 설치된 인공위성을 이용하는데, 사용 기한이 지난 인공위성을 제대로 수거할 기술을 아직 개발하지 못했기 때문이었어요. 무분별한 앱의 사용으로 지구 밖은 완전히 쓰레기로 뒤덮여 버렸습니다. 더 끔찍한 소식은 그 쓰레기들은 총알의 일곱 배가 된다는 무시무시한 속도로 지구를 회전하고 있다는 거였죠. 그건 앱을 켜 두고 대화하고 밥 먹고 섹스하는 남편과 나의 관계만큼이나 무시무시하거나 아슬아슬한 일이었죠."

                    - 최정화 [휴대폰하다]에서 발췌 (위 영상의 게시자가 발췌한 구절)
                </div>
            </div>
        </div>
    )
}

export default DigitalDetox;